import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useDebouncedCallback } from 'use-debounce';
import Swal from "sweetalert2"


import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';

import Input from 'components/Form/LabelInput'
import Dropdown from 'components/Dropdown'
import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import Table from 'components/Table'
import Pagination from 'components/Pagination'
import DatePicker from 'components/Form/DatePicker'
import BtnOrange from 'components/Button/BtnOrange'
import AnalyticsSkeleton from 'components/Skeleton/AnalyticsSkeleton'

import { formatDate, addDays, downloadFile } from "utils/index"

import {
    InputsContainer,
    AnalyticsContainer,
    CustomTooltipContainer,
    CustomTooltipDesc,
    CustomTooltipIntro,
    CustomTooltipLabel,
    CustomSearchInputContainer
}
    from "./styled"

import {
    handleListUsersActionsReports,
    handleAnalyticsUsersActionsReports,
    handleAnalyticsUsersActionsReportsXlsx,
    handleAnalyticsUsersActionsReportsPdf
} from "services/api/usersActionsReports"

export default function ReportsCollection() {
    const [analytics, setAnalytics] = useState([])
    const [rechartData, setRechartData] = useState([])

    const [initialDate, setInitialDate] = useState(addDays(new Date(), -7))
    const [endDate, setEndDate] = useState(new Date())

    const [usersActionReports, setUsersActionReports] = useState(null) //useState({ data: [] })

    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')

    const [isDownloading, setIsDownloading] = useState(false)
    const [isFiltering, setIsFiltering] = useState(false)

    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })

    const debouncedSearch = useDebouncedCallback(
        (value) => {
            setSearch(value);
        },
        1000
    );

    const formik = useFormik({
        initialValues: {
            search: '',
            initial_date: initialDate,
            end_date: endDate
        }
    })

    useEffect(() => {
        (async () => {
            try {
                const data = await handleListUsersActionsReports({
                    term: search,
                    currentPage: page,
                    perPage: 10,
                    initialDate: initialDate.toISOString(),
                    endDate: endDate.toISOString()
                })

                setUsersActionReports(data)
            } catch (error) { }
        })()
    }, [page, search, initialDate, endDate])

    useEffect(() => {
        (async () => {
            try {
                const data = await handleAnalyticsUsersActionsReports({
                    initialDate: initialDate.toISOString(),
                    endDate: endDate.toISOString(),
                    term: search
                })

                setAnalytics(data)
            } catch (error) { } finally {
                setIsFiltering(false)
            }
        })()
    }, [initialDate, endDate, search])

    useEffect(() => {
        const data = analytics.map(analytic => ({
            name: analytic.report.name,
            pv: analytic.quantity
        }))

        setRechartData(data)
    }, [analytics])

    useEffect(() => {
        debouncedSearch(formik.values.search)
    }, [formik.values.search, debouncedSearch])

    const handleFilterAnalytics = () => {
        setIsFiltering(true)

        setInitialDate(new Date(formik.values.initial_date))
        setEndDate(new Date(formik.values.end_date))
    }

    const handleDownloadXlsx = async () => {
        setIsDownloading(true)

        try {
            const data = await handleAnalyticsUsersActionsReportsXlsx({
                img: `${process.env.REACT_APP_URL_APP}/logo-big.png`,
                color: "ff0fff95",
                initialDate: initialDate.toISOString(),
                endDate: endDate.toISOString(),
                term: search
            })

            downloadFile(data, "Analytics.xlsx")

            Swal.fire(
                'Sucesso',
                'Exportação efetuada com sucesso!',
                'success'
            )
        } catch (error) {
            Swal.fire(
                'Erro',
                'Houve um problema ao efetuar a exportação!',
                'error'
            )
        } finally {
            setIsDownloading(false)
        }
    }

    const handleDownloadPdf = async () => {
        setIsDownloading(true)

        try {
            const data = await handleAnalyticsUsersActionsReportsPdf({
                img: `${process.env.REACT_APP_URL_APP}/logo-big.png`,
                color: "#0fff95",
                initialDate: initialDate.toISOString(),
                endDate: endDate.toISOString(),
                term: search
            })

            downloadFile(data, "Analytics.pdf")

            Swal.fire(
                'Sucesso',
                'Exportação efetuada com sucesso!',
                'success'
            )
        } catch (error) {
            Swal.fire(
                'Erro',
                'Houve um problema ao efetuar a exportação!',
                'error'
            )
        } finally {
            setIsDownloading(false)
        }
    }

    const CustomTooltip = ({ active, payload, label }) => {
        const users = analytics.find(analytic => analytic.report.name === label)?.users

        return active
            && payload
            && payload.length
            && <CustomTooltipContainer>
                <CustomTooltipLabel>{label}</CustomTooltipLabel>

                <CustomTooltipIntro>
                    {users.length === 1
                        ? `${users.length} usuário acessou este relatório:`
                        : `${users.length} usuários acessaram este relatório:`}
                </CustomTooltipIntro>

                <CustomTooltipDesc>
                    {users.map(user => `${user.name} (${user.quantity})`).join(", ")}
                </CustomTooltipDesc>
            </CustomTooltipContainer>
    };

    const onMouseEnter = event => {
        const tooltipSize = {
            x: event.x,
            y: event.y
        }

        if (window.innerWidth <= 350) {
            tooltipSize.x -= tooltipSize.x * 0.7
        } else if (window.innerWidth <= 400) {
            tooltipSize.x -= tooltipSize.x * 0.6
        } else if (window.innerWidth <= 500) {
            tooltipSize.x -= tooltipSize.x * 0.4
        } else if (window.innerWidth <= 600) {
            tooltipSize.x -= tooltipSize.x * 0.3
        } else if (window.innerWidth <= 900) {
            tooltipSize.x -= tooltipSize.x * 0.6
        } else if (window.innerWidth <= 1200) {
            tooltipSize.x -= tooltipSize.x * 0.35
        } else if (window.innerWidth <= 1300) {
            tooltipSize.x -= tooltipSize.x * 0.35
        } else {
            tooltipSize.x -= tooltipSize.x * 0.15
        }

        setTooltipPosition({ x: tooltipSize.x, y: tooltipSize.y })
    }

    return (
        <>
            <TitleBar label="Analytics" currentPage="" />

            <Content>
                <InputsContainer>
                    <div className='pickers'>
                        <DatePicker name="initial_date" label="De" formik={formik} />
                        <DatePicker name="end_date" label="Até" formik={formik} />
                    </div>

                    <div className='actions'>
                        <BtnOrange isLoading={isFiltering} style={{ width: "100px", height: "40px" }} onClick={handleFilterAnalytics}>
                            Filtrar
                        </BtnOrange>

                        <Dropdown
                            isLoading={isDownloading}
                            text="Exportar"
                            items={[
                                {
                                    text: "PDF",
                                    onClick: handleDownloadPdf
                                },
                                {
                                    text: "Excel",
                                    onClick: handleDownloadXlsx
                                }
                            ]}
                        />
                    </div>
                </InputsContainer>

                <AnalyticsContainer>
                    <ResponsiveContainer width="100%" height={"100%"}>
                        <BarChart width={500} height={300} data={rechartData}>
                            <CartesianGrid strokeDasharray="3 3" />

                            <XAxis dataKey="name" />
                            <YAxis />

                            <Tooltip
                                allowEscapeViewBox={{ y: true, x: true }}
                                cursor={false}
                                position={tooltipPosition}
                                wrapperStyle={{ pointerEvents: 'auto' }}
                                content={<CustomTooltip />}
                            />

                            <Bar
                                onMouseEnter={onMouseEnter}
                                dataKey="pv"
                                name="Acessos"
                                fill="#15272F"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </AnalyticsContainer>

                <Card>
                    <CustomSearchInputContainer>
                        <Input
                            placeholder="Buscar..."
                            name="search"
                            label=""
                            formik={formik}
                        />
                    </CustomSearchInputContainer>

                    <Table>
                        <thead>
                            <tr>
                                <th width="60">#</th>
                                <th>Empresa</th>
                                <th>Usuário</th>
                                <th>Relatório</th>
                                <th>Data de entrada</th>
                                <th>Data de saída</th>
                                <th>IP</th>
                            </tr>
                        </thead>

                        {usersActionReports &&
                            <tbody>
                                {usersActionReports.map(reportsCollection => (
                                    <tr key={reportsCollection.id}>
                                        <td>{reportsCollection.id}</td>
                                        <td>{reportsCollection.user?.company.name}</td>
                                        <td>{reportsCollection.user.name}</td>
                                        <td>{reportsCollection.report.name}</td>
                                        <td>{formatDate(reportsCollection.arrival_date)}</td>
                                        <td>{formatDate(reportsCollection.departure_date)}</td>
                                        <td>{reportsCollection.ip}</td>
                                    </tr>
                                ))}
                            </tbody>
                        }

                        {!usersActionReports  && <AnalyticsSkeleton />}      
                    </Table>
                    {usersActionReports?.length === 0 &&
                        <div style={{ width: "100%", textAlign: "center", padding: "10px 10px 20px 10px" }}>Analytics dos usuários não foram encontrados</div>}
                </Card>
                {/* 
                {!!usersActionReports.pagination?.total && (
                    <Pagination
                        pageCount={usersActionReports.pagination?.lastPage}
                        handlePageClick={({ selected }) => setPage(selected + 1)}
                    />
                )} */}
            </Content>
        </>
    )
}